import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { generateColor } from "../../../helpers/utils";
import { Tag, TagHealth } from "../../layout/Tag";
import { PageSiteProps } from "./Site";
import { useUser } from "../../context/UserContext";

export default function TableSystems({ systems, site }: PageSiteProps) {
    const { hasScope } = useUser();
    const { t } = useTranslation();

    const tdCommissioned = (node) => {
        let commissionedColor = "Orange";
        if (node.commissionedPercent === 100) {
            commissionedColor = "Green";
        } else if (node.commissionedPercent === 0) {
            commissionedColor = "Gray";
        } else if (node.commissionedPercent > 100) {
            commissionedColor = "Red";
        }
        const ret = (
            <Tag bgColor={generateColor(commissionedColor)}>
                {node.commissionedPercent <= 100
                    ? `${node.commissionedPercent}%`
                    : t("Error")}
            </Tag>
        );

        return ret;
    };

    return (
        <table className="table-auto w-full my-3">
            <thead>
                <tr>
                    <th>{t("Cluster")}</th>
                    <th className="hidden md:table-cell">{t("Hostname")}</th>
                    <th className="text-center">{t("Health")}</th>
                    <th className="text-center">{t("System Status")}</th>
                    <th className="text-center hidden md:table-cell">
                        {t("Commissioned")}
                    </th>
                </tr>
            </thead>
            <tbody>
                {systems.edges.map(({ node }) => (
                    <tr
                        key={node.macAddress}
                        className={classNames(
                            node.uiStatus === "Connected" ? "" : "unreachable"
                        )}
                    >
                        <td className="max-w-[50px] truncate">
                            {node.highTraffic ? (
                                <>{node.uiName}</>
                            ) : (
                                <Link
                                    to={`/sites/${site.reference}/${node.macAddress}`}
                                >
                                    {node.uiName}
                                </Link>
                            )}
                        </td>
                        <td className="hidden md:table-cell">
                            {node.hostname}
                        </td>
                        <td className="text-center">
                            <TagHealth value={node.healthScore} />
                        </td>
                        <td className="text-center">
                            <Tag bgColor={generateColor(node.uiStatus)}>
                                {t(node.uiStatus)}
                            </Tag>
                        </td>
                        <td className="text-center hidden md:table-cell">
                            {tdCommissioned(node)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
