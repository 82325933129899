import { createContext, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoClose } from "react-icons/io5";

type ModalProps = {
    isOpen: boolean;
    onClose?: () => void | null;
    title?: string;
    children: React.ReactNode;
    size?:
        | "max-w-md"
        | "max-w-xl"
        | "max-w-2xl"
        | "max-w-3xl"
        | "max-w-4xl"
        | "max-w-5xl"
        | "max-w-lg";
};

// Create the Modal Context
export const ModalContext = createContext({
    modals: {},
    openModal: (modalId: string) => {},
    closeModal: (modalId: string) => {},
    isModalOpen: (modalId: string) => false,
});

// Create the Provider Component
export const ModalProvider = ({ children }) => {
    const [modals, setModals] = useState({});

    const openModal = (modalId: string) => {
        setModals((prev) => ({ ...prev, [modalId]: true }));
    };

    const closeModal = (modalId: string) => {
        setModals((prev) => ({ ...prev, [modalId]: false }));
    };

    const isModalOpen = (modalId: string) => {
        return modals[modalId] || false;
    };

    return (
        <ModalContext.Provider
            value={{ modals, openModal, closeModal, isModalOpen }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export default function Modal({
    isOpen,
    onClose = null,
    title = "",
    children,
    size = "max-w-md", // sizes: max-w-md, max-w-lg, max-w-xl, max-w-2xl
}: ModalProps) {
    if (!isOpen) return null;

    let onCloseHandler = onClose;
    if (!onClose) {
        onCloseHandler = () => {};
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={onCloseHandler}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className={`w-full ${size} transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
                                >
                                    {onClose && (
                                        <button
                                            type="button"
                                            className="absolute top-5 right-5 text-gray-400 hover:text-gray-600"
                                            onClick={onCloseHandler}
                                            tabIndex={-1}
                                        >
                                            <IoClose className="h-8 w-8" />
                                        </button>
                                    )}

                                    {title && (
                                        <Dialog.Title
                                            as="h2"
                                            className="text-2xl font-medium text-gray-800 border-b pb-2"
                                        >
                                            {title}
                                        </Dialog.Title>
                                    )}

                                    {children}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
