import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MdOutlineCloudOff } from "react-icons/md";
import { HiCheckCircle } from "react-icons/hi";
import classNames from "classnames";

import Modal from "../../layout/Modal";
import {
    emitAnalyticsEvent,
    generateColor,
    setAnalyticsEventProps,
} from "../../../helpers/utils";
import { useMutation, useQuery } from "@apollo/client";
import { useLoading } from "../../context/LoadingContext";
import {
    MUTATION_ISSUE_ADD_RESOLUTION,
    MUTATION_ISSUE_ESCALATE,
} from "../../../graphql/mutations";
import { useUser } from "../../context/UserContext";
import { Tag } from "../../layout/Tag";
import { QUERY_ISSUE_V2 } from "../../../graphql/queries";
import { updateSystemIssueCache } from "../../../graphql/cache";
import { TimeAgo } from "../../../helpers";

export type IssuesSectionProps = {
    site: CoreSite;
    issues: Connection<CoreIssue>;
};

export type IssueSectionProps = {
    site?: CoreSite;
    issue: CoreIssue;
};

const EscalteButton = ({ issue }: IssueSectionProps) => {
    const { t } = useTranslation();

    const { user } = useUser();

    const [isModalOpen, setModalOpen] = useState(false);
    const closeModal = () => setModalOpen(false);

    const [submitFormMutation] = useMutation(MUTATION_ISSUE_ESCALATE);
    const { setLoading } = useLoading();

    const handleSubmit = () => {
        setLoading(true);
        submitFormMutation({
            variables: {
                email: user.email,
                number: issue.number,
                intercomUserId: window.intercomUserData.id,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                updateSystemIssueCache(issue, {
                    status: "Escalated",
                });
                window.Intercom(
                    "showConversation",
                    response.data.organisation.escalateIssue.conversationId
                );
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <button
                type="button"
                className="btn danger"
                onClick={() => setModalOpen(true)}
            >
                {t("BUTTON-ESCALTE-TO-SEV-1")}
            </button>
            {isModalOpen && (
                <Modal
                    title={`${t("ISSUE-MODAL-CONFIRM-TITLE")} #${issue.number}`}
                    children={
                        <>
                            <div className="flex my-3">
                                <div className=" w-[100px] flex items-center justify-center">
                                    <MdOutlineCloudOff className="text-red-600 font-extrabold text-4xl" />
                                </div>
                                <div className="w-full">
                                    <Trans i18nKey="ISSUE-CONFIRM-ESCALATE-TO-SEV-1-TEXT" />
                                </div>
                            </div>
                            <div className="flex justify-end gap-4">
                                <button
                                    type="button"
                                    className="btn danger button-confirm-escalte"
                                    onClick={handleSubmit}
                                >
                                    {t("BUTTON-CONFIRM-ESCALTE")}
                                </button>
                            </div>
                        </>
                    }
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    size="max-w-2xl"
                />
            )}
        </>
    );
};

const IssueAddResolution = ({ onChange, issue }) => {
    useEffect(() => {
        onChange(issue.resolutionNotes);
    }, [issue.resolutionNotes]);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        onChange(event.target.value);
    };

    return (
        <textarea
            className="w-full h-24 p-2 border border-gray-300 rounded"
            placeholder="Add resolution"
            onChange={handleInputChange}
            defaultValue={issue.resolutionNotes}
        />
    );
};

const IssueModal = ({ issueNumber, isModalOpen, closeModal }) => {
    const { t } = useTranslation();

    const buttonRef = useRef(null);
    useEffect(() => {
        if (isModalOpen && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [isModalOpen]);

    const { data, loading } = useQuery(QUERY_ISSUE_V2, {
        variables: {
            number: issueNumber,
        },
        pollInterval: 3000,
        fetchPolicy: "network-only",
    });

    const [resolution, setResolution] = useState<string>("");

    const [submitFormMutation] = useMutation(MUTATION_ISSUE_ADD_RESOLUTION);
    const { setLoading } = useLoading();

    const handleAddResolution = () => {
        setLoading(true);
        submitFormMutation({
            variables: {
                number: issue.number,
                resolutionType: "",
                resolutionNotes: resolution,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (loading) return null;

    const issue = data.organisation.issuesv2.edges[0].node as CoreIssue;

    return (
        <Modal
            title={`${t("ISSUE-MODAL-TITLE")} #${issue.number}`}
            children={
                <>
                    <div className="flex my-3">
                        <div className=" w-[100px] flex items-center justify-center">
                            <MdOutlineCloudOff className="text-red-600 font-extrabold text-4xl" />
                        </div>
                        <div className="w-full">
                            <div className="flex justify-between w-full">
                                <div className="flex-1 p-2">
                                    <div className="my-2">
                                        {t("LABEL-DEVICE-TYPE")}:{" "}
                                        <strong>
                                            <Trans>
                                                {`ISSUE-DEVICE-TYPE-${issue.issueType}`}
                                            </Trans>
                                        </strong>
                                    </div>
                                    <div className="my-2">
                                        {t("LABEL-LAST-SEEN")}:{" "}
                                        <strong>
                                            <TimeAgo date={issue.raisedAt} />
                                        </strong>
                                    </div>
                                    <div>
                                        {t("LABEL-DEVICE-STATUS", "Status")}:{" "}
                                        <strong>
                                            <Trans>{issue.status}</Trans>
                                        </strong>
                                    </div>
                                </div>
                                <div className="flex-1 p-2">
                                    <div className="my-2">
                                        {t("LABEL-LOCATION")}:{" "}
                                        <strong>{issue.clientUserRef}</strong>
                                    </div>
                                    {issue.location && (
                                        <div className="my-2">
                                            {t(
                                                "PeripheralOffline" ===
                                                    issue.issueType
                                                    ? "LABEL-SENSORS-PERIPHERAL"
                                                    : "LABEL-SENSORS"
                                            )}
                                            : <strong>{issue.location}</strong>
                                        </div>
                                    )}
                                    {"PeripheralOffline" !==
                                        issue.issueType && (
                                        <div className="my-2">
                                            {t("LABEL-SENSORS-AFFECTED")}:{" "}
                                            <strong>
                                                {issue.devicesAffected}{" "}
                                            </strong>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {issue.status === "Resolved" && (
                                <IssueAddResolution
                                    issue={issue}
                                    onChange={setResolution}
                                />
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end gap-4">
                        {issue.status === "Resolved" && (
                            <button
                                type="button"
                                className="btn danger"
                                onClick={handleAddResolution}
                            >
                                {t("BUTTON-ADD-RESOLUTION")}
                            </button>
                        )}
                        {!["Escalated", "Resolved"].includes(issue.status) && (
                            <EscalteButton issue={issue} />
                        )}
                        {!["Resolved"].includes(issue.status) && (
                            <button
                                type="button"
                                className={classNames(
                                    "btn danger",
                                    issue.issueType,
                                    issue.status,
                                    issue.severity
                                )}
                                ref={buttonRef}
                            >
                                {t("BUTTON-OPEN-TROUBLESHOOTER")}
                            </button>
                        )}
                    </div>
                </>
            }
            isOpen={isModalOpen}
            onClose={closeModal}
            size="max-w-2xl"
        />
    );
};

const IssuesTable = ({ site: site, issues }: IssuesSectionProps) => {
    const { t } = useTranslation();

    const closeModal = () => {
        setIssueNumber(null);
    };
    const [issueNumber, setIssueNumber] = useState<number | null>(null);

    const handleClick = (issue: CoreIssue) => {
        setIssueNumber(issue.number);
        setAnalyticsEventProps({ site, issue });
        emitAnalyticsEvent("site-open-issue");
    };

    if (issues.edges.length > 0 === false) {
        return (
            <div className="my-4">
                <Tag className="success" icon={HiCheckCircle}>
                    {t("LABLEL-NO-ISSUES", "No issues")}
                </Tag>
            </div>
        );
    }

    return (
        <>
            <table className="table-auto w-full my-3">
                <thead>
                    <tr>
                        <th>
                            {t("DEVICE-HEALTH-TH-DEVICE-TYPE", "Device Type")}
                        </th>
                        <th>
                            {t("DEVICE-HEALTH-TH-ISSUE-TYPE", "Issue Type")}
                        </th>
                        <th>{t("LABEL-LOCATION")}</th>
                        <th className="text-center">
                            {t("DEVICE-HEALTH-TH-OCCURED", "Occured")}
                        </th>
                        <th className="text-center">
                            {t("DEVICE-HEALTH-TH-STATUS", "Status")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {issues.edges.map(({ node }) => (
                        <tr
                            key={`issue-tr-${node.number}`}
                            className={classNames(
                                issueNumber && issueNumber === node.number
                                    ? "selected"
                                    : "hover-bg"
                            )}
                            onClick={() => handleClick(node)}
                        >
                            <td className="hover-underline">
                                {t(`ISSUE-DEVICE-TYPE-${node.issueType}`)}
                            </td>
                            <td>
                                <Tag bgColor={generateColor("Disconnected")}>
                                    {t(`ISSUE-TITLE-${node.issueType}`)}
                                </Tag>
                            </td>
                            <td>{node.clientUserRef}</td>
                            <td className="text-center">
                                <TimeAgo date={node.raisedAt} />
                            </td>
                            <td className="text-center">
                                <Tag bgColor={generateColor(node.status)}>
                                    {t(node.status)}
                                </Tag>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {issueNumber && (
                <IssueModal
                    issueNumber={issueNumber}
                    isModalOpen={!!issueNumber}
                    closeModal={closeModal}
                />
            )}
        </>
    );
};

export default IssuesTable;
