import { IoBagHandle, IoCart, IoPeople, IoPieChart } from "react-icons/io5";

import SampleChart from "../../samples/SampleChart";
import SamplePieChart from "../../samples/SamplePieChart";
import SampleTable from "../../samples/SampleTable";
import SampleProducts from "../../samples/SampleProducts";
import { useUser } from "../../context/UserContext";
import Permission from "../../layout/Permision";

export default function PageDashboard() {
    const { hasScope } = useUser();

    console.log('hasScope("admin")', hasScope("admin"));
    console.log('hasScope("installer")', hasScope("installer"));

    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-4">
                Permissions:
                <Permission scopes={"installer"}>
                    <div>Installer</div>
                </Permission>
                <Permission scopes={"admin"}>
                    <div>Admin</div>
                </Permission>
                <Permission scopes={["admin", "installer"]}>
                    <div>Admin and installer</div>
                </Permission>
                {hasScope("installer") && <div>Abc</div>}
            </div>
            <div className="flex gap-4">
                <BoxWrapper>
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
                        <IoBagHandle className="text-2xl text-white" />
                    </div>
                    <div className="pl-4">
                        <span className="text-sm text-gray-500 font-light">
                            Total Sales
                        </span>
                        <div className="flex items-center">
                            <strong className="text-xl text-gray-700 font-semibold">
                                $54232
                            </strong>
                            <span className="text-sm text-green-500 pl-2">
                                +343
                            </span>
                        </div>
                    </div>
                </BoxWrapper>
                <BoxWrapper>
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-600">
                        <IoPieChart className="text-2xl text-white" />
                    </div>
                    <div className="pl-4">
                        <span className="text-sm text-gray-500 font-light">
                            Total Expenses
                        </span>
                        <div className="flex items-center">
                            <strong className="text-xl text-gray-700 font-semibold">
                                $3423
                            </strong>
                            <span className="text-sm text-green-500 pl-2">
                                -343
                            </span>
                        </div>
                    </div>
                </BoxWrapper>
                <BoxWrapper>
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
                        <IoPeople className="text-2xl text-white" />
                    </div>
                    <div className="pl-4">
                        <span className="text-sm text-gray-500 font-light">
                            Total Customers
                        </span>
                        <div className="flex items-center">
                            <strong className="text-xl text-gray-700 font-semibold">
                                12313
                            </strong>
                            <span className="text-sm text-red-500 pl-2">
                                -30
                            </span>
                        </div>
                    </div>
                </BoxWrapper>
                <BoxWrapper>
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
                        <IoCart className="text-2xl text-white" />
                    </div>
                    <div className="pl-4">
                        <span className="text-sm text-gray-500 font-light">
                            Total Orders
                        </span>
                        <div className="flex items-center">
                            <strong className="text-xl text-gray-700 font-semibold">
                                16432
                            </strong>
                            <span className="text-sm text-red-500 pl-2">
                                -43
                            </span>
                        </div>
                    </div>
                </BoxWrapper>
            </div>

            <div className="flex gap-4">
                <SampleChart />
                <SamplePieChart />
            </div>

            <div className="flex gap-4">
                <SampleTable />
                <SampleProducts />
            </div>
        </div>
    );
}

function BoxWrapper({ children }) {
    return (
        <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">
            {children}
        </div>
    );
}
