import { useEffect } from "react";
import { useQuery } from "@apollo/client";

import { useLoading } from "../components/context/LoadingContext";
import { useConnection } from "../components/context/ConnectionContext";
import { useUser } from "../components/context/UserContext";

type FetchPolicy = "network-only" | "cache-and-network" | null;

interface UseQueryWithParamsProps<TData, TVariables> {
    query: any;
    variables?: TVariables | null;
    fetchPolicy?: FetchPolicy;
    accessScope?: UserScopeType | null;
}

class CoreFetchingError extends Error {
    type: string;
    additionalInfo: Record<string, any> | null;

    constructor(
        message: string,
        type: string = "GraphQLError",
        additionalInfo: Record<string, any> | null = null
    ) {
        super(message);
        this.name = "CoreFetchingError";
        this.type = type;
        this.additionalInfo = additionalInfo;
    }
}

export function useQueryWithParams<TData, TVariables>({
    query,
    variables = null,
    fetchPolicy = null,
    accessScope = null,
}: UseQueryWithParamsProps<TData, TVariables>) {
    const { hasScope } = useUser();
    const { setLoading } = useLoading();
    const { setConnectionLost } = useConnection();

    if (accessScope && !hasScope(accessScope)) {
        throw new Error("NOT_ALLOWED");
    }

    const { loading, error, data, refetch, networkStatus } = useQuery<
        TData,
        TVariables
    >(query, {
        variables,
        fetchPolicy,
    });

    const raiseAmpError = () => {
        if (!error) {
            return;
        }
        window.ElasticAmp.captureError(
            new CoreFetchingError(
                `${query.loc.source.body}, variables: ${JSON.stringify(variables)}, error: ${error.message}`
            )
        );
    };

    useEffect(() => {
        setLoading(loading);
    }, [loading, setLoading]);

    useEffect(() => {
        setConnectionLost(!!(error && error.networkError));
    }, [error, setConnectionLost]);

    raiseAmpError();
    useEffect(() => {
        raiseAmpError();
        // if user is not logged in, reload the page
        if (
            error &&
            error.networkError &&
            error.networkError.message ===
                "Response not successful: Received status code 401"
        ) {
            window.location.reload();
            return;
        }
    }, [error]);

    return { loading, error, data, refetch };
}
