import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApolloQueryResult } from "@apollo/client";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Content } from "../../layout/Content";
import { Box, Boxes } from "../../layout/Boxes";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import Tabs from "../../layout/Tabs";
import Modal from "../../layout/Modal";
import ConfigureClusterForm from "./ConfigureClusterForm";
import SystemMaintenanceMode from "./MaintenanceMode";
import ClusterInfo from "./ClusterInfo";
import PeripheralsSection from "./PeripheralsSection";
import AislesSection from "./AislesSection";
import { useUser } from "../../context/UserContext";
import DropdownClusters from "../../layout/Breadcrumbs/DropdownClusters";
import DropdownSites from "../../layout/Breadcrumbs/DropdownSites";
import {
    emitAnalyticsEvent,
    generateColor,
    setAnalyticsEventProps,
} from "../../../helpers/utils";
import ClusterEvents from "./Events";
import HeartbeatComponent from "./Heartbeat";
import ForceUpgradeSystem from "./ForceUpgrade";
import { TagHealth } from "../../layout/Tag";

type PageClusterProps = {
    system: CoreSystem;
    site: CoreSite;
    refetch: () => Promise<ApolloQueryResult<any>>;
};

export default function PageCluster({
    system,
    site,
    refetch,
}: PageClusterProps) {
    const { t } = useTranslation();
    const { hasScope } = useUser();
    const navigate = useNavigate();

    const config: ClusterConfig = {
        noAisles: system.configNoAisles,
        noColumns: site.configNoColumns,
        noColumnsOv: site.configNoColumnsOv,
    };

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        // { label: t("Sites"), href: "/sites" },
        <DropdownSites siteName={site.name} />,
        {
            label: site.name,
            href: `/sites/${site.reference}`,
        },
        <DropdownClusters
            clusterName={system.uiName}
            siteReference={site.reference}
        />,
    ];

    const tabPaths = [
        { label: t("Overview"), href: "#" },
        // { label: t("Historic Data"), href: "#history" },
        { label: t("System Info"), href: "#info" },
    ];

    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.hash || "");

    const [isClusterConfigModalOpen, setClusterConfigModalOpen] =
        useState<boolean>(false);
    const closeClusterConfigModal = () => setClusterConfigModalOpen(false);

    const [selectedNode, setSelectedNode] = useState<CoreNode | null>(
        system.nodes[0] || null
    );
    const selectNode = (node: CoreNode) => {
        setSelectedNode(node);

        setAnalyticsEventProps({ site, system, aisle: node });
        if (node) {
            emitAnalyticsEvent("aisle-selected");
        }
    };

    const selectedNodeRef = useRef(selectedNode);
    useEffect(() => {
        selectedNodeRef.current = selectedNode;
    }, [selectedNode]);

    const refetchCallback = useCallback(async () => {
        try {
            await refetch(); // Perform the refetch
        } catch (error) {
            console.error("Error refetching cluster data", error);
        }

        const currentSelectedNode = selectedNodeRef.current;

        if (!currentSelectedNode) {
            return;
        }

        // Re-select and update the node after refetch
        const node = system.nodes.find(
            (n) => n.address === currentSelectedNode?.address
        );
        setSelectedNode(node); // Update selectedNode
    }, [refetch, selectedNode, system.nodes, setSelectedNode]);

    useEffect(() => {
        setActiveTab(location.hash || "");
    }, [location.hash]);

    const pathChanged = useRef(false);
    useEffect(() => {
        pathChanged.current = true;
    }, [location.pathname]);

    useEffect(() => {
        if (pathChanged.current) {
            pathChanged.current = false;
            setAnalyticsEventProps({ site, system });
            emitAnalyticsEvent("cluster-open");
        }
    }, [site, system, pathChanged]);

    useEffect(() => {
        if (system.maintenanceMode === false && system.commissioned === true) {
            return;
        }
        const intervalId = setInterval(async () => {
            await refetchCallback();
        }, 1000 * 5); // 5 seconds

        return () => clearInterval(intervalId);
    }, [refetchCallback, system.maintenanceMode, system.commissioned]);

    if (system.highTraffic === true) {
        navigate(`/sites/${site.reference}`);
        return null;
    }

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <div className="flex flex-wrap justify-between items-center ">
                <div>
                    <div>
                        <div className="w-full flex items-center gap-2">
                            <div
                                className="flex w-8 h-8 rounded"
                                style={{
                                    backgroundColor: generateColor(
                                        system.uiStatus
                                    ),
                                }}
                            ></div>
                            <div className="text-2xl font-bold">
                                <h1>{system.uiName}</h1>
                            </div>
                            <TagHealth value={system.healthScore} />
                        </div>
                        <div className="text-sm text-gray-400 mt-1">
                            {t(system.uiStatus)} | {system.hostname} |{" "}
                            {t("Last sync")}{" "}
                            <HeartbeatComponent system={system} />
                        </div>
                    </div>
                </div>
                <div className="border border-stone-300 rounded p-1 buttons mb-2">
                    {hasScope("superadmin") && (
                        <Link
                            className="button light"
                            to={`/systems/${system.macAddress}`}
                        >
                            Admin View
                        </Link>
                    )}
                    <button
                        id="btn-cluster-open-config"
                        className="button light w-48"
                        onClick={() => setClusterConfigModalOpen(true)}
                    >
                        {t("System Configuration")}
                    </button>
                </div>
            </div>

            <div className="md:flex md:flex-wrap-reverse md:justify-between md:gap-3">
                <Tabs paths={tabPaths} activeTab={activeTab} />
                <SystemMaintenanceMode system={system} refetch={refetch} />
            </div>

            {/* OVERVIEW TAB */}
            {/* <Boxes
                className={classNames(activeTab === "#history" ? "" : "hidden")}
            >
                <Box>{t("Historic Data Box")}</Box>
            </Boxes> */}

            {/* INFO TAB */}
            <Boxes
                className={classNames(activeTab === "#info" ? "" : "hidden")}
            >
                <ClusterInfo system={system} site={site} refetch={refetch} />
            </Boxes>

            {/* OVERVIEW TAB */}
            <div className={classNames(activeTab === "" ? "" : "hidden")}>
                <Boxes className={classNames(!config.noAisles ? "hidden" : "")}>
                    <AislesSection
                        system={system}
                        remapCallback={refetchCallback}
                        selectNode={selectNode}
                        selectedNode={selectedNode}
                        config={config}
                    />

                    <PeripheralsSection
                        selectedNode={selectedNode}
                        selectNode={selectNode}
                        system={system}
                        site={site}
                        refetch={refetch}
                    />
                </Boxes>

                <Box className={classNames(!config.noAisles ? "" : "hidden")}>
                    <div className="flex justify-center m-4">
                        <button
                            id="btn-cluster-open-config-from-empty"
                            className="button info w-48 h-8"
                            onClick={() => setClusterConfigModalOpen(true)}
                        >
                            {t("Configure")}
                        </button>
                    </div>
                </Box>

                <Modal
                    title={`${system.uiName}`}
                    children={
                        <ConfigureClusterForm
                            closeModal={closeClusterConfigModal}
                            cluster={{
                                macAddress: system.macAddress,
                                name: system.uiName,
                                clientRef: system.clientRef,
                                aisles: config.noAisles,
                            }}
                            site={site}
                            refetch={refetch}
                        />
                    }
                    isOpen={isClusterConfigModalOpen}
                    onClose={closeClusterConfigModal}
                    size="max-w-md"
                />
            </div>

            <ClusterEvents system={system} refetch={refetch} />
            <ForceUpgradeSystem system={system} refetch={refetch} />
        </Content>
    );
}
