import React, { useState } from "react";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineMail } from "react-icons/hi";

import { MUTATION_RESEND_INVITE } from "../../../graphql/mutations";
import { useLoading } from "../../context/LoadingContext";
import { Form } from "../../layout/Form";
import { ActionButton } from "../../layout/Actions";
import Modal from "../../layout/Modal";

type Errors = {
    form?: string;
};

interface UserActionProps {
    user: CoreUser;
    refetch: () => Promise<ApolloQueryResult<any>>;
    closeModal?: () => void;
}

const ResendInvite = ({ user, closeModal }: UserActionProps) => {
    const { t } = useTranslation();

    const [errors, setErrors] = useState<Errors>({});

    const { setLoading } = useLoading();

    const [submitFormMutation] = useMutation(MUTATION_RESEND_INVITE);

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        setLoading(true);

        submitFormMutation({
            variables: {
                email: user.email,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                closeModal();
            })
            .catch((err) => {
                setErrors({ ...errors, form: t(err.message) });
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Form props={{ onSubmit: handleSubmit }} error={errors.form}>
            <Trans
                i18nKey="RESEND-INVITE-TEXT"
                values={{ email: user.email }}
            ></Trans>

            <div className="buttons justify-end">
                <button className="button light" onClick={closeModal}>
                    {t("Cancel")}
                </button>
                <button type="submit" className="button info">
                    {t("Send")}
                </button>
            </div>
        </Form>
    );
};

export default function ResendInviteButton({ user, refetch }: UserActionProps) {
    const { t } = useTranslation();

    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const closeEditModal = () => setEditModalOpen(false);

    return (
        <div>
            <ActionButton
                icon={HiOutlineMail}
                label={t("Resend Invite")}
                onClick={() => setEditModalOpen(true)}
                color="Orange"
            />
            {isEditModalOpen && (
                <Modal
                    title={t("Resend Invite")}
                    children={
                        <ResendInvite
                            closeModal={closeEditModal}
                            user={user}
                            refetch={refetch}
                        />
                    }
                    isOpen={isEditModalOpen}
                    onClose={closeEditModal}
                    size="max-w-xl"
                />
            )}
        </div>
    );
}
