import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationTriangle } from "react-icons/fa";

import Alert from "../../layout/Alert";

type HighTrafficAlertProps = {
    systems: Connection<CoreSystem>;
};

const HighTrafficAlert = ({ systems }: HighTrafficAlertProps) => {
    const { t } = useTranslation();

    const isHighTraffic = useMemo(
        () => systems.edges.some((edge) => edge.node.highTraffic),
        [systems.edges]
    );

    if (isHighTraffic === false) {
        return null;
    }

    return (
        <Alert>
            <div className="flex gap-2 items-center">
                <div className="p-2 text-orange-300">
                    <FaExclamationTriangle size={21} />
                </div>
                <div>
                    <div className="flex flex-col gap-1 py-1">
                        <div className="font-semibold">
                            {t("HIGH_TRAFFIC_ALERT_TITLE")}
                        </div>
                        <div className="text-sm">
                            {t("HIGH_TRAFFIC_ALERT_DESCRIPTION")}
                        </div>
                    </div>
                </div>
            </div>
        </Alert>
    );
};

export default HighTrafficAlert;
