import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimeAgo } from "../../../helpers";

export const SyncComponent = ({ refetch }) => {
    const { t } = useTranslation();

    const [lastClicked, setLastClicked] = useState(new Date().toISOString());
    const [elapsedTime, setElapsedTime] = useState(
        <TimeAgo date={lastClicked} />
    );

    const handleClick = () => {
        setLastClicked(new Date().toISOString());
        refetch();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setElapsedTime(<TimeAgo date={lastClicked} />);
        }, 1000);

        return () => clearInterval(interval);
    }, [lastClicked]);

    return (
        <div className="flex gap-4">
            <div className="text-sm text-gray-400 mt-1 flex gap-1 justify-between w-full">
                <div>{t("Last sync")}</div>
                <div>{elapsedTime}</div>
            </div>
            <button className="button indigo" onClick={handleClick}>
                {t("Refresh")}
            </button>
        </div>
    );
};
