import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi";
import classNames from "classnames";
import { useUser } from "../../context/UserContext";

const DropdownSuperAdmin = () => {
    const { user, uiScope: impersonationScope, setUiScope: setImpersonationScope } = useUser();

    if (!user?.superadmin) return null; // Only accessible to superadmins

    const handleChange = (scope: UserScopeType) => {
        setImpersonationScope(scope);
    };

    const scopes = [
        { label: "Superadmin", value: "superadmin" },
        { label: "Admin", value: "admin" },
        { label: "Maintainer", value: "maintainer" },
    ] as { label: string; value: UserScopeType }[];

    return (
        <Menu as="div" className="relative">
            <div className="group flex items-center gap-2 hover:cursor-pointer">
                <Menu.Button className="flex">
                    {scopes.find((scope) => scope.value === impersonationScope)
                        ?.label || "Superadmin"}{" "}
                    <HiChevronDown
                        fontSize={20}
                        className="group-hover:opacity-100 opacity-0 transition-opacity duration-150 ease-in-out"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {/* bottom-full */}
                    {scopes.map((option) => (
                        <Menu.Item key={option.value}>
                            <div
                                onClick={() => handleChange(option.value)}
                                className={classNames(
                                    impersonationScope === option.value &&
                                        "bg-gray-200",
                                    "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                                )}
                            >
                                {option.label}
                            </div>
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default DropdownSuperAdmin;
