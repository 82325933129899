import { generateColor, isProd, isTest, isUat } from "../../../helpers/utils";
import { useUser } from "../../context/UserContext";
import { Tag } from "../Tag";

const EnvTag = () => {
    const { hasScope } = useUser();

    if (!hasScope("superadmin")) {
        return null;
    }

    let label = "DEV";
    if (isTest) {
        label = "TEST";
    } else if (isUat) {
        label = "UAT";
    } else if (isProd) {
        label = "PROD";
    }

    return <Tag bgColor={generateColor(label)}>{label}</Tag>;
};

export default EnvTag;
