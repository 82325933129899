import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { generateColor } from "../../../../helpers/utils";
import { Tag } from "../../../layout/Tag";

export default function AisleTable({
    rows,
    selectedNode,
    selectNode,
    className = "",
    clickable = true,
}) {
    const { t } = useTranslation();

    return (
        <table className={classNames("w-full", className)}>
            <thead>
                <tr>
                    <th>{t("Name")}</th>
                    <th className="text-center hidden md:table-cell">
                        {t("Address")}
                    </th>
                    <th className="text-center">{t("Aisle Status")}</th>
                    <th className="text-center">{t("Commissioned")}</th>
                    <th className="text-center hidden md:table-cell">
                        {t("Sensor Status")}
                    </th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row, index: number) => (
                    <tr
                        key={`aisle-${index}-${row.address}`}
                        className={classNames(
                            selectedNode &&
                                row.td_address === selectedNode.address
                                ? "selected"
                                : "hover-bg",
                            row.td_status === "Connected" ? "" : "unreachable"
                        )}
                        onClick={() =>
                            clickable ? selectNode(row.node) : null
                        }
                    >
                        <td className="hover-underline">{row.td_name}</td>
                        <td className="text-center hidden md:table-cell">
                            {row.td_address}
                        </td>
                        <td className="text-center">
                            <Tag bgColor={generateColor(row.td_status)}>
                                {t(row.td_status)}
                            </Tag>
                        </td>
                        <td className="text-center">
                            {row.td_commisioned}
                            {/* {row.td_commisioned && (
                                <Tag
                                    bgColor={generateColor(
                                        row.node.commissioned ? "Yes" : "No"
                                    )}
                                >
                                    
                                </Tag>
                            )} */}
                        </td>
                        <td className="text-center hidden md:table-cell">
                            {row.td_sensors}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
