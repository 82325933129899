import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useSubscription } from "@apollo/client";
import classNames from "classnames";

import { SUBSCRIPTION_CONTROLLER_EVENTS_WITH_CORRELATION_ID } from "../../../../graphql/subscriptions";
import { MUTATION_START_CALIBRATION } from "../../../../graphql/mutations";
import { useLoading } from "../../../context/LoadingContext";
import { Tag } from "../../../layout/Tag";
import {
    configValue,
    generateColor,
    generateUUID,
} from "../../../../helpers/utils";
import Instructions from "./Instructions";
import SensorGrid from "./SensorGrid";
import { generateInitialStructure, turnOnNextInStructure } from "./helpers";
import { useCalibrationTimer } from "./timers";
import { StartCommissioningButton } from "./Buttons";
import { useUser } from "../../../context/UserContext";
import SensorsValidSection from "../PeripheralsSection/SensorsValid";
import VerifySensorsBtn from "../PeripheralsSection/VerifySensors";

interface SensorCalibrationProps {
    system: CoreSystem;
    selectedNode: CoreNode;
    site: CoreSite;
    closeModal: () => void;
    isCommissioningRunning: boolean;
    setCommissioningRunning: (running: boolean) => void;
    refetch: () => void;
    handleBack: () => void;
    sensorsState: SensorsState;
}

type Status = "Ready" | "Waiting" | "Running" | "Completed" | "Error";

export type StructureConfig = {
    noColumns: number;
    noColumnsOv: number;
    noSensors: number;
    noSensorsOv: number;
};

export type SensorsStructure = { sensors: { on: boolean }[] }[];

export default function SensorCalibration({
    system,
    selectedNode,
    site,
    closeModal,
    isCommissioningRunning,
    setCommissioningRunning,
    refetch,
    handleBack,
    sensorsState,
}: SensorCalibrationProps) {
    const { hasScope } = useUser();
    const { setLoading } = useLoading();
    const [status, setStatus] = useState<Status>("Ready");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const processedEventIds = useRef(new Set());

    const { startTimeout, startElapsedTimer, stopTimers, elapsedTime } =
        useCalibrationTimer();

    const correlationId = useMemo(() => generateUUID(), []);

    const config: StructureConfig = useMemo(() => {
        return {
            noColumns: configValue(
                selectedNode.configNoColumns,
                site.configNoColumns
            ),
            noColumnsOv: configValue(
                selectedNode.configNoColumnsOv,
                site.configNoColumnsOv
            ),
            noSensors: selectedNode.configNoSensors,
            noSensorsOv: selectedNode.configNoSensorsOv,
        };
    }, [
        selectedNode.configNoColumns,
        site.configNoColumns,
        selectedNode.configNoColumnsOv,
        site.configNoColumnsOv,
        selectedNode.configNoSensors,
        selectedNode.configNoSensorsOv,
    ]);
    const initialStructure = useMemo(
        () => generateInitialStructure(config),
        [config]
    );
    const [sensorStructure, setSensorStructure] = useState(initialStructure);

    const { data, error } = useSubscription(
        SUBSCRIPTION_CONTROLLER_EVENTS_WITH_CORRELATION_ID,
        {
            variables: {
                macAddresses: [system.macAddress],
                correlationId: correlationId,
            },
        }
    );

    const [executeStartCalibration] = useMutation(MUTATION_START_CALIBRATION);

    const stopProcess = useCallback(
        (message) => {
            setErrorMessage(message);
            setStatus("Error");
            setCommissioningRunning(false);
            setLoading(false);
            stopTimers();
        },
        [
            setErrorMessage,
            setStatus,
            setCommissioningRunning,
            setLoading,
            stopTimers,
        ]
    );

    const handleCommissionFailed = useCallback(
        (payload) => {
            const { num_sensors, expected_sensor_count } = payload;
            const error =
                num_sensors > expected_sensor_count
                    ? `Failed to start: Too many sensors. (Expected: ${expected_sensor_count} but found: ${num_sensors})`
                    : `Failed to start: Not enough sensors. (Expected: ${expected_sensor_count} but found: ${num_sensors})`;
            stopProcess(error);
        },
        [stopProcess]
    );

    const handleCommissionStarted = useCallback(() => {
        setStatus("Running");
        setLoading(false);
        stopTimers();
    }, [setStatus, setLoading, stopTimers]);

    const handleCommissionCompleted = useCallback(() => {
        setStatus("Completed");
        setCommissioningRunning(false);
        refetch();
        stopTimers();
    }, [setStatus, setCommissioningRunning, refetch, stopTimers]);

    const turnOnNextSensor = useCallback(() => {
        console.log("Turning on next sensor...");
        const structure = turnOnNextInStructure(sensorStructure);
        setSensorStructure(structure);
    }, [sensorStructure]);

    const startCalibration = useCallback(() => {
        setStatus("Waiting");
        setCommissioningRunning(true);
        setSensorStructure(initialStructure);
        setErrorMessage("");
        setLoading(true);

        startTimeout(() => stopProcess("Error: Process timed out."));
        startElapsedTimer();

        executeStartCalibration({
            variables: {
                macAddress: system.macAddress,
                correlationId: correlationId,
                branch: Number(selectedNode.address),
                expectedSensorCount:
                    config.noColumns * config.noSensors +
                    config.noColumnsOv * config.noSensorsOv,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            });
    }, [
        setStatus,
        setCommissioningRunning,
        setSensorStructure,
        setLoading,
        startTimeout,
        startElapsedTimer,
        executeStartCalibration,
        system,
        selectedNode,
        config,
        initialStructure,
        correlationId,
        stopProcess,
    ]);

    const handleSubscriptionEvents = useCallback(() => {
        if (!data || !data.events) {
            return;
        }

        if (data.events.correlationId !== correlationId) {
            return;
        }

        const currentEventId = data.events.id;

        // Check if the event ID has already been processed
        if (processedEventIds.current.has(currentEventId)) {
            return; // Event already processed, skip
        }

        // Mark the event as processed
        processedEventIds.current.add(currentEventId);

        if (hasScope("superadmin")) {
            console.log("Calibration event:", data.events);
        }

        // Do nothing if the status is already final
        if (["Ready", "Completed", "Error"].includes(status)) {
            return;
        }

        switch (data.events.kind) {
            case "commission_failed_to_start":
                handleCommissionFailed(data.events.payload);
                break;
            case "commission_started":
                handleCommissionStarted();
                break;
            case "sensor_indexed":
                turnOnNextSensor();
                break;
            case "commission_completed":
                handleCommissionCompleted();
                break;
            default:
                break;
        }
    }, [
        data,
        status,
        hasScope,
        correlationId,
        handleCommissionFailed,
        turnOnNextSensor,
        handleCommissionStarted,
        handleCommissionCompleted,
    ]);

    useEffect(() => {
        handleSubscriptionEvents();
    }, [handleSubscriptionEvents, data, status]);

    useEffect(() => {
        if (
            JSON.stringify(sensorStructure) !==
                JSON.stringify(initialStructure) &&
            ["Ready", "Error"].includes(status)
        ) {
            setSensorStructure(initialStructure);
        }
    }, [initialStructure, sensorStructure, status]);

    if (error) return <p>Error :(</p>;

    return (
        <div>
            <Instructions />

            <div className="flex flex-row gap-5">
                {!["Ready", "Error"].includes(status) && (
                    <Tag
                        bgColor={generateColor(status)}
                        className="w-48 h-8 rounded-sm justify-center"
                    >
                        {status} {elapsedTime > 0 && `(${elapsedTime}s)`}
                    </Tag>
                )}

                <div
                    className={classNames(
                        !["Ready", "Error"].includes(status) ? "hidden" : ""
                    )}
                >
                    <StartCommissioningButton
                        system={system}
                        startCalibration={startCalibration}
                        refetch={refetch}
                        sensorsState={sensorsState}
                    />
                </div>

                {errorMessage && (
                    <div className="error mt-1">{errorMessage}</div>
                )}
            </div>

            <hr />

            <SensorGrid sensorStructure={sensorStructure} />

            <SensorsValidSection
                system={system}
                selectedNode={selectedNode}
                sensorsState={sensorsState}
            />

            <div className="buttons justify-between">
                <button
                    className={classNames(
                        "button light w-32"
                        // isCommissioningRunning ? "hidden" : ""
                    )}
                    // disabled={isCommissioningRunning}
                    onClick={handleBack}
                >
                    Back
                </button>

                {status === "Completed" ? (
                    <VerifySensorsBtn
                        system={system}
                        selectedNode={selectedNode}
                        onClick={() => {
                            closeModal();
                        }}
                    />
                ) : (
                    <button
                        id="btn-aisle-open-verify-after-calibration"
                        className="button light w-32 disabled"
                        disabled={true}
                    >
                        Verify
                    </button>
                )}
            </div>
        </div>
    );
}
