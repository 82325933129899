import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const TimeAgo = ({ date }): ReactElement => {
    const { t } = useTranslation();

    if (!date) {
        return <>-</>;
    }
    let sufix = "Z";
    if (date.endsWith("Z")) {
        sufix = "";
    }

    const serverDateUTC = new Date(date + sufix);
    const localNow = new Date();

    const diffInMilliseconds = localNow.getTime() - serverDateUTC.getTime();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (isNaN(diffInMilliseconds)) {
        return <>-</>;
    } else if (diffInSeconds < 60) {
        return <>{t("TIME-AGO-SECONDS", { count: diffInSeconds })}</>;
    } else if (diffInMinutes < 60) {
        return <>{t("TIME-AGO-MINUTES", { count: diffInMinutes })}</>;
    } else if (diffInHours < 24) {
        return <>{t("TIME-AGO-HOURS", { count: diffInHours })}</>;
    } else {
        return <>{t("TIME-AGO-DAYS", { count: diffInDays })}</>;
    }
};

export { TimeAgo };
