import client from "./apollo";

export function updateSystemNodeCache(
    node: CoreNode,
    newNode: Partial<CoreNode>
) {
    client.cache.modify({
        id: client.cache.identify({
            __typename: "SystemNode",
            address: node.address,
        }),
        fields: {
            ...Object.keys(newNode).reduce((acc, key) => {
                acc[key] = () => newNode[key as keyof CoreNode];
                return acc;
            }, {}),
        },
    });
}

export function updateSystemIssueCache(
    issue: CoreIssue,
    newIssue: Partial<CoreIssue>
) {
    client.cache.modify({
        id: client.cache.identify({
            __typename: "SystemIssue",
            number: issue.number,
        }),
        fields: {
            ...Object.keys(newIssue).reduce((acc, key) => {
                acc[key] = () => newIssue[key as keyof CoreIssue];
                return acc;
            }, {}),
        },
    });
}

// client.cache.modify({
//     id: client.cache.identify({
//         __typename: "SystemIssue",
//         number: issue.number,
//     }),
//     fields: {
//         status() {
//             return "Escalated";
//         },
//     },
// });
