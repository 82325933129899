import classNames from "classnames";

import { generateColor, getUiStatus } from "../../../helpers/utils";
import { Tag, TagHealth } from "../../layout/Tag";
import { TimeAgo } from "../../../helpers";

export default function SystemHeader({ system }: { system: CoreSystem }) {
    const parts = [
        system.clientRef,
        system.hostname,
        system.macAddress,
        system.siteReference,
    ].filter((part) => part);
    const title = parts[0];
    const description = parts.slice(1).join(" | ");

    const { bgColour, label } = getUiStatus(system.status, system.reachable);

    return (
        <div className="flex justify-between items-center">
            <div>
                <div className="w-full flex items-center gap-2">
                    <div
                        className={classNames(
                            "flex w-16 h-12 text-white items-center justify-center rounded",
                            bgColour
                        )}
                    >
                        {label}
                    </div>
                    <div className="flex gap-4 text-2xl font-bold">
                        <h1>{title}</h1>

                        <TagHealth value={system.healthScore} />

                        {system.highTraffic && (
                            <Tag bgColor={generateColor("Orange")}>
                                High Traffic
                            </Tag>
                        )}
                    </div>
                </div>
                {description && (
                    <div className="text-sm text-gray-700">{description}</div>
                )}
            </div>
            <div className="flex gap-2">
                <div className="text-right text-sm text-gray-700">
                    <div className="text-xs">Heartbeats:</div>
                    <div>
                        Comms: <TimeAgo date={system.heartbeatCommsAt} />
                    </div>
                    <div>
                        Service: <TimeAgo date={system.heartbeatServiceAt} />
                    </div>
                </div>
            </div>
        </div>
    );
}
