import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MUTATION_SYSTEM_UPGRADE } from "../../../graphql/mutations";
import Modal from "../../layout/Modal";
import classNames from "classnames";

type UpgradeSystemProps = {
    system: CoreSystem;
    refetch: () => void;
};

const UpgradeSystem = ({ system, refetch }: UpgradeSystemProps) => {
    const { t } = useTranslation();

    const [executeUpgrade] = useMutation(MUTATION_SYSTEM_UPGRADE);

    const [isModalOpen, setIsModalOpen] = useState(true);
    const closeModal = () => setIsModalOpen(false);

    const [running, setRunning] = useState(false);

    const handleUpgrade = () => {
        setRunning(true);
        executeUpgrade({
            variables: {
                macAddress: system.macAddress,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setRunning(false);
                refetch();
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            });
    };

    let onClose = closeModal;
    // let onClose = null;
    // if (hasScope("superadmin")) {
    //     onClose = closeModal;
    // }

    return (
        <Modal
            title="Upgrade System"
            onClose={onClose}
            isOpen={isModalOpen}
            size="max-w-xl"
            children={
                <>
                    <div className="mt-4 text-sm">
                        <p>
                            {t(
                                "Use this tool to upgrade the system to the latest version."
                            )}
                        </p>
                    </div>
                    <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-2">
                        <button
                            id="btn-system-upgrade"
                            className={classNames(
                                "button info w-32",
                                running ? "light" : ""
                            )}
                            type="button"
                            onClick={handleUpgrade}
                            disabled={running}
                        >
                            {running ? t("Loading...") : t("Upgrade")}
                        </button>
                    </div>
                </>
            }
        />
    );
};

const ForceUpgradeSystem = ({ system, refetch }: UpgradeSystemProps) => {
    if (!system) {
        return null;
    }

    if (!system.maintenanceMode) {
        return null;
    }

    if (!system.reachable) {
        return null;
    }

    if (["UP_TO_DATE", "UPGRADING"].includes(system.softwareStatus)) {
        return null;
    }

    return <UpgradeSystem system={system} refetch={refetch} />;
};

export default ForceUpgradeSystem;
