import { createBrowserRouter } from "react-router-dom";

import { rootLoader, RootRoute, IndexRoute } from "./root";
import { SystemRoute, SystemsRoute } from "./systems";
import { UsersRoute } from "./users";
import { SiteRoute, SitesRoute } from "./sites";
import { ClusterRoute } from "./cluster";
import { ProjectRoute, ProjectsRoute } from "./projects";
import { IssueNewRoute, IssuesRoute, IssueViewRoute } from "./issues";
import { TemplatesRoute } from "./templates";
import ErrorPage from "../components/pages/Error";
import { loginAction, LoginRoute, logoutAction, logoutLoader } from "./auth";
import { SupportRoute } from "./support";
import NoAccess from "./no-access";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <RootRoute />,
        loader: rootLoader,
        errorElement: <ErrorPage />,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    { index: true, element: <IndexRoute /> },
                    {
                        path: "systems",
                        element: <SystemsRoute />,
                    },
                    {
                        path: "systems/:macAddress",
                        element: <SystemRoute />,
                    },
                    {
                        path: "support",
                        element: <SupportRoute />,
                    },
                    {
                        path: "support/issues",
                        element: <IssuesRoute />,
                    },
                    {
                        path: "support/issues/new",
                        element: <IssueNewRoute />,
                    },
                    {
                        path: "support/issues/:number",
                        element: <IssueViewRoute />,
                    },
                    {
                        path: "users",
                        element: <UsersRoute />,
                    },
                    {
                        path: "projects",
                        element: <ProjectsRoute />,
                    },
                    {
                        path: "projects/:code",
                        element: <ProjectRoute />,
                    },
                    {
                        path: "sites",
                        element: <SitesRoute />,
                    },
                    {
                        path: "sites/:code",
                        element: <SiteRoute />,
                    },
                    {
                        path: "sites/:site/:macAddress",
                        element: <ClusterRoute />,
                    },
                    {
                        path: "templates",
                        element: <TemplatesRoute />,
                    },
                ],
            },
        ],
    },
    // Routes outside the Layout
    {
        path: "login",
        element: <LoginRoute />,
        action: loginAction,
        errorElement: <ErrorPage />,
    },
    {
        path: "signup",
        element: <LoginRoute />,
        action: loginAction,
        errorElement: <ErrorPage />,
    },
    {
        path: "logout",
        action: logoutAction,
        loader: logoutLoader,
        element: <div>Logging out...</div>,
        errorElement: <ErrorPage />,
    },
    {
        path: "no-access",
        element: <NoAccess />,
    },
]);
