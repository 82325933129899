import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { MUTATION_DEMO_CALIBRATION } from "../../../../graphql/mutations";
import Modal, { ModalContext } from "../../../layout/Modal";

type VerifySensorsProps = {
    system: CoreSystem;
    selectedNode: CoreNode;
    label?: string;
    onClick?: (() => void) | null;
};

type VerifyStep = "Verify" | "Starting..." | "Running...";

const VerifySensors = ({ system, selectedNode }: VerifySensorsProps) => {
    const { t } = useTranslation();
    const [executeVerifySensors] = useMutation(MUTATION_DEMO_CALIBRATION);

    const [step, setStep] = useState<VerifyStep>("Verify");

    const handleVerifySensors = () => {
        setStep("Starting...");
        executeVerifySensors({
            variables: {
                macAddress: system.macAddress,
                branch: Number(selectedNode.address),
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                setStep("Running...");
                setTimeout(() => {
                    setStep("Verify");
                }, 3000);
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            });
    };

    const buttonRef = useRef(null);
    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    });

    if (step === "Verify") {
        return (
            <button
                id="btn-aisle-start-verify"
                className={"button info w-32"}
                type="button"
                onClick={handleVerifySensors}
                ref={buttonRef}
            >
                {t("Verify")}
            </button>
        );
    }

    return (
        <button
            id="btn-aisle-start-verify"
            className={"button info w-32 light disabled"}
            type="button"
            disabled={true}
        >
            {t(step)}
        </button>
    );
};

const VerifySensorsModal = ({
    system,
    selectedNode,
    label,
    onClick = null,
}: VerifySensorsProps) => {
    const { t } = useTranslation();
    const { openModal, closeModal, isModalOpen } = useContext(ModalContext);

    const MODAL_ID = "verifiy-sensors-order-modal";

    const handleClick = () => {
        openModal(MODAL_ID);
        if (onClick) {
            onClick();
        }
    };

    return (
        <>
            <button
                id="btn-aisle-open-verify"
                className="button indigo min-w-32"
                onClick={handleClick}
            >
                {t(label)}
            </button>

            <Modal
                title={t("Verify Sensor Order")}
                isOpen={isModalOpen(MODAL_ID)}
                onClose={() => closeModal(MODAL_ID)}
                size="max-w-2xl"
                children={
                    <>
                        <div className="mt-4 text-sm">
                            <p>
                                {t(
                                    "Use this tool to verify that the order of the sensors is correct."
                                )}
                            </p>
                            <p>
                                {t(
                                    "After clicking Verify, the red sensor lights on the floor will switch off. They will then turn on in sequence, starting from A column."
                                )}
                            </p>
                            <p>
                                {t(
                                    "Once all on, they will then turn off in the same sequence."
                                )}
                            </p>
                            <p>
                                {t(
                                    "Once all off, they will all turn back on after a few seconds."
                                )}
                            </p>
                            <p>
                                {t(
                                    "If the sequence is not correct, re-run the Commissioning Tool for this aisle."
                                )}
                            </p>
                        </div>
                        <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-2">
                            <button
                                className="button light  w-32"
                                type="button"
                                onClick={() => closeModal(MODAL_ID)}
                            >
                                {t("Close")}
                            </button>
                            <VerifySensors
                                system={system}
                                selectedNode={selectedNode}
                            />
                        </div>
                    </>
                }
            />
        </>
    );
};

const VerifySensorsBtn = ({
    system,
    selectedNode,
    label = "Verify Sensor Order",
    onClick = null,
}: VerifySensorsProps) => {
    if (!system) {
        return null;
    }

    if (!system.maintenanceMode) {
        return null;
    }

    if (!selectedNode.commissioned) {
        return null;
    }

    return (
        <VerifySensorsModal
            system={system}
            selectedNode={selectedNode}
            label={label}
            onClick={onClick}
        />
    );
};

export default VerifySensorsBtn;
