import Cookies from "js-cookie";

import config from "../config";

const hideLoading = () => {
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.style.display = "none";
        // loadingElement.remove(); //
    }
};
const showLoading = () => {
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.style.display = "block";
    }
};

const getSignOutUrl = () => {
    return config.oktagonUrl + "/signout?client_origin=" + window.location.href;
};

const redirectToOktagonLogin = () => {
    window.location =
        config.oktagonUrl + "/signin?client_origin=" + window.location.href;
};

const isAuthenticated = () => {
    let expirationTime = Cookies.get("maxiluxcloud_expiration_time");
    return !!expirationTime;
};

const mustRefreshToken = () => {
    let expirationTime = parseInt(Cookies.get("maxiluxcloud_expiration_time"));
    let currentTime = Math.floor(Date.now() / 1000);

    return expirationTime <= currentTime;
};

const refreshToken = async () => {
    let response = await fetch(config.oktagonUrl + "/refresh-token", {
        method: "GET",
        credentials: "include",
        mode: "cors",
    });

    if (response.status !== 200) {
        redirectToOktagonLogin();
    }
};

async function fetchWithExponentialBackoff(url, options) {
    let retries = 5;
    let delay = 1000;

    for (let i = 0; i < retries; i++) {
        try {
            let response = await fetch(url, options);
            if (!response.status) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response; // Return response if successful
        } catch (error) {
            const message = `[CORE/whoami] Attempt ${i + 1} failed. Retrying in ${delay / 1000}s...`;
            console.log(message);
            try {
                window.ElasticAmp.captureError(new Error(message));
            } catch (error) {
                console.error("AMP error", error);
            }

            if (i < retries - 1) {
                let currentDelay = delay;
                await new Promise((resolve) =>
                    setTimeout(resolve, currentDelay)
                );
                delay *= 2; // Exponentially increase the delay
            } else {
                window.location.reload();
                return null;
            }
        }
    }
}

const authenticate = async () => {
    console.log("Authenticating...");

    showLoading();
    const response = await fetchWithExponentialBackoff(
        config.apiUrl + "/whoami",
        {
            method: "GET",
            credentials: "include",
            mode: "cors",
        }
    );
    hideLoading();

    if (!response) {
        return null;
    }

    // let response = await fetch(config.apiUrl + "/whoami", {
    //     method: "GET",
    //     credentials: "include",
    //     mode: "cors",
    // });

    let responseJson = await response.json();

    switch (response.status) {
        case 200:
            setInterval(async () => {
                if (isAuthenticated() && mustRefreshToken()) {
                    await refreshToken();
                }
            }, 360000);
            break;
        case 401:
            if (
                responseJson.detail &&
                responseJson.detail === "User has no associated accounts"
            ) {
                window.location.href = "/no-access";
                return null;
            }

            redirectToOktagonLogin();
            return null;
        default:
            console.log("Failed to verify user...", responseJson);
            window.ElasticAmp.captureError(
                new Error(`[CORE] Failed to verify user: ${responseJson}`)
            );
            return null;
    }

    return {
        ...responseJson,
        accounts: [
            ...responseJson.associated_account_references.map((account) => {
                return {
                    value: account.account_reference,
                    label: account.account_name,
                    scopes: account.scopes,
                };
            }),
        ],
    };
};

export default authenticate;
export { getSignOutUrl };
