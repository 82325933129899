import { useQuery } from "@apollo/client";
import { createContext, useState, useContext, useMemo, useEffect } from "react";
import { QUERY_SITES } from "../../graphql/queries";

const UserContext = createContext<{
    user: User | null;
    setUser: (user: User | null) => void;
    hasScope: (scope: UserScopeType) => boolean;
    uiScope: UserScopeType;
    setUiScope: (scope: UserScopeType) => void;
}>({
    user: null,
    setUser: () => {},
    hasScope: () => false,
    uiScope: "superadmin",
    setUiScope: () => {},
});

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<{
    children: React.ReactNode;
    initialUser?: User | null;
}> = ({ children, initialUser }) => {
    const [user, setUser] = useState<User | null>(initialUser || null);
    const [uiScope, setUiScope] = useState<UserScopeType>(() => {
        const userScope = localStorage.getItem("portal.userScope");
        return userScope ? JSON.parse(userScope) : "superadmin";
    });

    useEffect(() => {
        localStorage.setItem("portal.userScope", JSON.stringify(uiScope));
    }, [uiScope]);

    const hasScope = useMemo(() => {
        return (scope: string) => {
            if (!user) {
                return false;
            }
            if (user.superadmin) {
                if (uiScope === "superadmin") {
                    return true;
                }
                return uiScope === scope;
            }

            const account = user?.accounts?.find(
                (row) => row.value === user.account_reference
            );
            return account.scopes?.includes(scope) || false;
        };
    }, [user, uiScope]);

    const { data: sites, refetch: refetch_sites } = useQuery(QUERY_SITES, {
        variables: { limit: 50, offset: 0 },
    });

    useEffect(() => {
        refetch_sites();
    }, [user, refetch_sites]);

    useEffect(() => {
        const connSites: Connection<CoreSite> = sites?.account.sites;
        if (connSites) {
            window.Mixpanel.people.set(
                "Authorized Sites",
                connSites?.edges.map((site) => site.node.reference).join(",")
            );
        }
    }, [sites]);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                hasScope,
                uiScope: uiScope,
                setUiScope: setUiScope,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
