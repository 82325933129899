import { Trans } from "react-i18next";

const Instructions = () => (
    <div className="text-sm mb-5 mt-5" id="calibration-instructions">
        <Trans
            i18nKey="CALIBRATION-INSTRUCTIONS"
            components={{ ol: <ol />, ul: <ul />, li: <li /> }}
        />
    </div>
);

export default Instructions;
