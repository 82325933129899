import { useParams } from "react-router-dom";

import { QUERY_CLUSTER } from "../graphql/queries";
import PageCluster from "../components/pages/cluster/Cluster";
import { ErrorComponent, NotFoundPage } from "../components/pages/Error";
import { useQueryWithParams } from "../graphql/useQueryWithParams";
import { filterSystem } from "../helpers/utils";
import { useMemo } from "react";

interface QueryClusterData {
    account: {
        site: CoreSite;
    };
    system: CoreSystem;
}

const ClusterRoute = () => {
    const { site, macAddress } = useParams<{
        site: string;
        macAddress: string;
    }>();

    const { loading, error, data, refetch } = useQueryWithParams<
        QueryClusterData,
        { site: string; macAddress: string }
    >({
        query: QUERY_CLUSTER,
        variables: { site, macAddress },
        fetchPolicy: "network-only",
    });

    let system = null;
    if (data && data.system) {
        system = data.system;
    }

    const filteredSystem = useMemo(
        () => (!system ? null : filterSystem(system)),
        [system]
    );

    if (loading) return null;

    if (error && error.message === "System does not belong to account.") {
        return <NotFoundPage />;
    }

    if (!data.system) {
        return <NotFoundPage />;
    }

    if (error && !data) {
        return <ErrorComponent error={error.message} refetch={refetch} />;
    }

    return (
        <PageCluster
            system={filteredSystem}
            site={data.account.site}
            refetch={refetch}
        />
    );
};

export { ClusterRoute };
