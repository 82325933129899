import { ApolloQueryResult } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { generateColor, nodeStatus } from "../../../../helpers/utils";
import { Box } from "../../../layout/Boxes";
import PeripheralsTable from "./Table";
import RunCommissioningToolBtn from "./RunCommissioningTool";
import SensorsControlBtn from "./SensorsControl";
import VerifySensorsBtn from "./VerifySensors";

type PeripheralsSectionPros = {
    selectedNode: CoreNode;
    selectNode: (node: CoreNode) => void;
    system: CoreSystem;
    site: CoreSite;
    refetch: () => Promise<ApolloQueryResult<any>>;
};

const PeripheralsSection = ({
    selectedNode,
    selectNode,
    system,
    site,
    refetch,
}: PeripheralsSectionPros) => {
    const { t } = useTranslation();

    if (!selectedNode) {
        return <></>;
    }

    const node = system.nodes.find((n) => n.address === selectedNode.address);

    return (
        <Box className="flex-wrap">
            <div className="xl:flex justify-between mb-4">
                <div className="flex gap-2 items-center mt-2">
                    <div
                        className="w-8 h-8 rounded"
                        style={{
                            backgroundColor: generateColor(
                                nodeStatus(node, system)
                            ),
                        }}
                    ></div>
                    <h1>{node.uiName}</h1>
                </div>
                <div className="flex gap-1 rounded border p-1 mt-1 flex-col md:flex-row ">
                    <SensorsControlBtn system={system} selectedNode={node} />
                    <VerifySensorsBtn system={system} selectedNode={node} />
                    <RunCommissioningToolBtn
                        selectedNode={node}
                        system={system}
                        site={site}
                        selectNode={selectNode}
                        refetch={refetch}
                    />
                </div>
            </div>

            <PeripheralsTable
                peripherals={node.uiPeripherals.peripheralsConfigured}
            />

            {node.uiPeripherals.peripheralsNotConfigured.length > 0 && (
                <>
                    <div className="mt-3 p-2 rounded flex justify-between text-sm bg-red-500 text-white">
                        <div>{t("NON_COMMISSIONED_SENSORS_DETECTED")}</div>
                        <div>{t("ACTION_RUN_COMMISSION")}</div>
                    </div>
                    <PeripheralsTable
                        peripherals={
                            node.uiPeripherals.peripheralsNotConfigured
                        }
                        className={"opacity-65"}
                    />
                </>
            )}
        </Box>
    );
};

export default PeripheralsSection;
