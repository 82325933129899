import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApolloQueryResult } from "@apollo/client";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import Tabs from "../../layout/Tabs";
import { Box, Boxes } from "../../layout/Boxes";
import { Content, ContentTop } from "../../layout/Content";
import Modal from "../../layout/Modal";
import ConfigureSiteForm from "./ConfigureSiteForm";
import AddSystemForm from "./AddSystemForm";
import {
    emitAnalyticsEvent,
    setAnalyticsEventProps,
} from "../../../helpers/utils";
import DropdownSites from "../../layout/Breadcrumbs/DropdownSites";
import TableSystems from "./TableSystems";
import { useUser } from "../../context/UserContext";
import HighTrafficAlert from "./HightTrafficAlert";
import IssuesTable from "./Issues";
import { SyncComponent } from "./Sync";

export type PageSiteProps = {
    site: CoreSite;
    systems: Connection<CoreSystem>;
    issues?: Connection<CoreIssue>;
    refetch: () => Promise<ApolloQueryResult<any>>;
};

type ViewStep = "no-config" | "no-systems" | "full";

const PageSite = ({ site, systems, issues, refetch }: PageSiteProps) => {
    const { t } = useTranslation();
    const { hasScope } = useUser();

    const [show] = useState(false);

    const breadcrumbPaths = [
        { label: t("Home"), href: "/" },
        // { label: t("Sites"), href: "/sites" },
        <DropdownSites siteName={site.name} />,
        { label: site.name },
    ];

    const tabPaths = [
        { label: t("Overview"), href: "#" },
        // { label: t("Historic Data"), href: "#history" },
        { label: t("Site Info"), href: "#info" },
    ];

    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.hash || "");

    const getViewStep = () => {
        if (site.configNoSystems === 0 || site.configNoAisles === 0) {
            return "no-config";
        } else if (systems.edges.length === 0) {
            return "no-systems";
        } else {
            return "full";
        }
    };

    const [viewStep, setViewStep] = useState<ViewStep>(() => getViewStep());

    const [isConfigurationModalOpen, setConfigurationModalOpen] = useState(
        viewStep === "no-config"
    );
    const closeConfigurationModal = () => setConfigurationModalOpen(false);

    const [isAddSystemModalOpen, setAddSystemModalOpen] = useState(
        viewStep === "no-systems"
    );
    const closeAddSystemModal = () => setAddSystemModalOpen(false);

    useEffect(() => {
        setActiveTab(location.hash || "");
    }, [location]);

    const pathChanged = useRef(false);
    useEffect(() => {
        pathChanged.current = true;
    }, [location.pathname]);

    useEffect(() => {
        if (pathChanged.current) {
            pathChanged.current = false;
            setAnalyticsEventProps({ site });
            emitAnalyticsEvent("site-open");
        }
    }, [site, pathChanged]);

    useEffect(() => {
        const newStep = getViewStep();
        if (newStep !== viewStep) {
            setViewStep(newStep);
        }
    }, [site, systems, viewStep]);

    return (
        <Content>
            <Breadcrumbs items={breadcrumbPaths} />

            <ContentTop h1={site.name}>
                <div className="border border-stone-300 rounded p-1 buttons">
                    <button
                        id="btn-site-open-config"
                        className="button light w-40"
                        onClick={() => setConfigurationModalOpen(true)}
                    >
                        {t("Site Configuration")}
                    </button>
                    <Modal
                        title={t("Configure Site ") + site.name}
                        children={
                            <ConfigureSiteForm
                                closeModal={closeConfigurationModal}
                                site={site}
                                refetch={refetch}
                            />
                        }
                        isOpen={isConfigurationModalOpen}
                        onClose={closeConfigurationModal}
                        size="max-w-xl"
                    />
                    <button
                        id="btn-site-open-add-system-modal"
                        className="button light w-40"
                        onClick={() => setAddSystemModalOpen(true)}
                    >
                        {t("+ Add System")}
                    </button>
                    <Modal
                        title={t("Add System")}
                        children={
                            <AddSystemForm
                                closeModal={closeAddSystemModal}
                                site={site}
                                refetch={refetch}
                            />
                        }
                        isOpen={isAddSystemModalOpen}
                        onClose={closeAddSystemModal}
                        size="max-w-lg"
                    />
                </div>
            </ContentTop>

            <div className="flex justify-between flex-wrap-reverse gap-3">
                <Tabs paths={tabPaths} activeTab={activeTab} />
            </div>

            <HighTrafficAlert systems={systems} />

            <Boxes
                className={classNames(activeTab === "#info" ? "" : "hidden")}
            >
                <Box title={t("Info")}>
                    <table className="table-auto w-full my-3">
                        <tbody>
                            <tr>
                                <td>{t("Name")}</td>
                                <td>{site.name}</td>
                            </tr>
                            <tr>
                                <td>{t("Region")}</td>
                                <td>{site.region}</td>
                            </tr>
                            <tr>
                                <td>{t("Location")}</td>
                                <td>{site.location}</td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
                <Box title={t("Configuration")}>
                    <table className="table-auto w-full my-3">
                        <tbody>
                            <tr>
                                <td>{t("Number of Systems")}</td>
                                <td>{site.configNoSystems}</td>
                            </tr>
                            <tr>
                                <td>{t("Aisles per System")}</td>
                                <td>{site.configNoAisles}</td>
                            </tr>
                            <tr>
                                <td>{t("Bag (4T) Columns per Aisle")}</td>
                                <td>{site.configNoColumns}</td>
                            </tr>
                            <tr>
                                <td>{t("OV Columns per Aisle")}</td>
                                <td>{site.configNoColumnsOv}</td>
                            </tr>
                            <tr>
                                <td>{t("Sensors per Bag (4T) Column")}</td>
                                <td>{site.configNoSensors}</td>
                            </tr>
                            <tr>
                                <td>{t("Sensors per OV")}</td>
                                <td>{site.configNoSensorsOv}</td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Boxes>

            <div
                className={classNames(
                    "flex gap-4",
                    activeTab === "" ? "" : "hidden"
                )}
            >
                <Box
                    title={t("Clusters")}
                    className={classNames(viewStep === "full" ? "" : "hidden")}
                >
                    <TableSystems
                        systems={systems}
                        site={site}
                        refetch={refetch}
                    />
                </Box>

                <div
                    className={classNames(
                        "bg-white rounded-sm flex flex-col flex-1",
                        viewStep === "full" ? "" : "hidden"
                    )}
                >
                    <div className="m-3 h-full">
                        <div className="flex justify-between">
                            <div>
                                <h2 className="text-lg">
                                    {t("DEVICE-HEALTH-TITLE", "Device Health")}
                                </h2>
                            </div>
                            <SyncComponent refetch={refetch} />
                        </div>
                        <IssuesTable site={site} issues={issues} />
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default PageSite;
