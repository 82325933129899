import { useTranslation } from "react-i18next";

import { ErrorComponent } from "../components/pages/Error";

export default function NoAccess() {
    const { t } = useTranslation();
    return (
        <ErrorComponent
            title={t("NO-ACCESS-TITLE")}
            description={t("NO-ACCESS-DESCRIPTION")}
        />
    );
}
