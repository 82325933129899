// // src/i18n.js
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import detector from "i18next-browser-languagedetector";
// import backend from "i18next-http-backend";

// const languages = ["en", "de", "fr", "es", "it", "ja"];

// i18n.use(backend)
//     .use(initReactI18next)
//     .use(detector)
//     .init({
//         fallbackLng: "en",
//         debug: false,
//         interpolation: {
//             escapeValue: false, // React already safes from xss
//         },
//         backend: {
//             loadPath: "/locales/{{lng}}/{{ns}}.json", // Path to the translation files
//         },
//         saveMissing: true,
//     });
////////

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";

import { isProd, isTest } from "./helpers/utils";

const languages = ["fr", "de", "en", "es", "it", "ja"];

if (isProd === false) {
    languages.push("dev");
}

const projectId = "76e4af77-6e7f-475c-8a89-50192b77024d";
const apiKey = "b6918c11-f06a-4e1c-80ee-0ee6a2704065";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            projectId: projectId,
            apiKey: apiKey,
            referenceLng: "en",
        },
        supportedLngs: languages,
        saveMissing: true,
        missingKeyHandler: async (lng, ns, key, fallbackValue) => {
            await fetch(
                `https://api.locize.io/missing/${projectId}/latest/${lng}/${ns}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ [key]: fallbackValue || key }),
                }
            );
        },
    });

////////

console.log("Detected language:", i18n.language);

export { languages };
export default i18n;
