import { isProd, isTest, isUat } from "./helpers/utils";

interface Config {
    oktagonUrl: string;
    apiUrl: string;
    wsUrl: string;
    cookieDomain: string;
}

const prodConfig: Config = {
    oktagonUrl: "https://auth.maxiluxcloud.com",
    apiUrl: "https://api.maxiluxcloud.com",
    wsUrl: "wss://api.maxiluxcloud.com",
    cookieDomain: ".maxiluxcloud.com",
};

const testConfig: Config = {
    oktagonUrl: "https://test-auth.maxiluxcloud.com",
    apiUrl: "https://api.maxiluxcloud.com",
    wsUrl: "wss://api.maxiluxcloud.com",
    cookieDomain: ".maxiluxcloud.com",
};

const uatConfig: Config = {
    oktagonUrl: "https://auth.maxiluxsystems-uat.com",
    apiUrl: "https://api.maxiluxsystems-uat.com",
    wsUrl: "wss://api.maxiluxsystems-uat.com",
    cookieDomain: ".maxiluxsystems-uat.com",
};

const devConfig: Config = {
    oktagonUrl: "http://localhost:8001",
    apiUrl: "http://localhost:8006",
    wsUrl: "ws://localhost:8006",
    cookieDomain: ".localhost",
};

const getConfig = (): Config => {
    if (isProd) return prodConfig;
    if (isTest) return testConfig;
    if (isUat) return uatConfig;
    return devConfig;
};

const config: Config = getConfig();

export default config;
