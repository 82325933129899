import { useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";

import Modal from "../../../layout/Modal";
import { useLoading } from "../../../context/LoadingContext";
import { MUTATION_REMAP } from "../../../../graphql/mutations";

export default function ModalRemap({ system, isOpen, setIsOpen }) {
    const [submitFormMutation] = useMutation(MUTATION_REMAP);
    const { setLoading } = useLoading();

    const buttonRef = useRef(null);
    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    });

    if (!system) {
        return null;
    }

    const handleSubmit = () => {
        setLoading(true);
        submitFormMutation({
            variables: {
                macAddress: system.macAddress,
            },
        })
            .then((response) => {
                console.log("Mutation response:", response);
                closeModal();
            })
            .catch((err) => {
                console.error("Mutation error:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeModal = () => setIsOpen(false);

    return (
        <Modal
            title="System - Remap"
            children={
                <>
                    <div className="flex flex-row gap-2 justify-end border-t mt-4 pt-5">
                        <button
                            className="button light"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button info"
                            type="button"
                            onClick={handleSubmit}
                            ref={buttonRef}
                        >
                            Execute
                        </button>
                    </div>
                </>
            }
            isOpen={isOpen}
            onClose={closeModal}
            size="max-w-md"
        />
    );
}
