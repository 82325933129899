import { TimeAgo } from "../../../helpers";

export default function SystemDockers({ system }: { system: CoreSystem }) {
    if (!system.containers) {
        return <h5 className="mb-2 text-xl font-medium ">No Containers</h5>;
    }
    return (
        <div>
            <h5 className="mb-2 text-xl font-medium ">Containers</h5>

            <hr className="border-t border-muted mb-4" />

            <div className="flex gap-2">
                <div className="w-max">
                    <table className="min-w-full table-auto">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Started</th>
                            </tr>
                        </thead>
                        <tbody>
                            {system.containers.map((container) => (
                                <tr key={container.name}>
                                    <td>{container.name}</td>
                                    <td>{container.image}</td>
                                    <td>
                                        <TimeAgo date={container.started} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex-grow overflow-auto max-h-72 bg-gray-100 p-2 rounded-md">
                    <pre className="text-xs ">
                        {JSON.stringify(system.networkInterfaces, null, 2)}
                    </pre>
                </div>
            </div>
        </div>
    );
}
